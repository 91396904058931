import React from "react";
import classNames from "classnames";
import { Container, Text, Image, AnimateIn } from "@atoms";

const Testimonial = ({
  attribution,
  className: _className,
  image,
  overlap,
  position,
  quote,
  showDivider,
}) => {
  return (
    <section
      className={classNames(
        "pb-20 md:pb-28",
        {
          "pt-20 md:pt-28": !overlap,
          "-mt-60 bg-gray pt-60 md:-mt-80 md:pt-80": overlap,
          "border-t-2 border-black": showDivider,
        },
        _className
      )}
    >
      <Container>
        <AnimateIn preset="fadeUpFast" className="flex gap-16">
          {image && (
            <div className="relative hidden aspect-square h-32 w-32 flex-shrink-0 overflow-hidden rounded-full md:block">
              <Image image={image} fill className="ronded-full" />
            </div>
          )}
          <div className="relative flex gap-2 pr-4 md:gap-4 md:pr-0">
            <span className="relative -top-1.5 text-[4rem] text-green md:-top-3 md:text-[8rem]">
              &ldquo;
            </span>
            <div className="flex flex-1 flex-col border-b-2 border-r-2 border-black pr-6 pb-6 md:pr-12 md:pb-12">
              <Text className="text-xl font-bold !leading-tight md:text-4xl">
                {quote}
              </Text>
              <div className="mt-4 flex items-center justify-start gap-4">
                {image && (
                  <div className="relative block aspect-square h-16 w-16 flex-shrink-0 overflow-hidden rounded-full md:hidden">
                    <Image image={image} fill className="ronded-full" />
                  </div>
                )}
                <div className="flex flex-col">
                  <Text variant="body" className="font-bold">
                    {attribution}
                  </Text>
                  <Text variant="sm">{position}</Text>
                </div>
              </div>
            </div>
          </div>
        </AnimateIn>
      </Container>
    </section>
  );
};

Testimonial.defaultProps = {};

export default Testimonial;
